
import { defineComponent, ref, onMounted, reactive, onUnmounted } from "vue";
import store from "@/store/index";
import { loadMicroApp } from "qiankun";
import servers from "@/config/servers";
import config from "@/config/app.config";
import { useRoute } from "vue-router";
import { eventCenter, event } from "@evideo/frontend-utils";
import { message as antdMessage } from "ant-design-vue";
import { setMicroAppMap } from "@/main";
import logger from "@evideo/logger";

export default defineComponent({
  name: "CustomPPT",
  props: {
    pptUrl: String,
    meta: Object,
  },
  emits: ["hidPPT"],
  setup(props, ctx) {
    let isAndroid = ref(true);
    let pptLoading = ref(true);
    let pptFun = "";
    let pptLoadTxt = ref("努力加载PPT应用中...");
    let firstPptUrl = reactive({
      url: "",
    });
    const router = useRoute();

    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    const hidPPT = () => {
      ctx.emit("hidPPT");
    };
    const handleFirstLoadingPPT = () => {
      logger.info("准备加载的pptjson", firstPptUrl);

      setTimeout(() => {
        pptLoading.value = false;
      }, 0);
      // 通知上级应用 可以加载ppt
      eventCenter.sub(`teachPptPC-load-ok`, () => {
        eventCenter.send(event.PPTIST_LOAD_PPT, firstPptUrl, (res) => {
          logger.info("通知上级应用 可以加载ppt", res);
        });
      });
      // eventCenter.send(event.PPTIST_LOAD_PPT, firstPptUrl, (res) => {
      //   logger.info("res", res);
      // });
    };
    const loadPPT = async () => {
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      logger.info("entryurl:", entryUrl);
      pptFun = await loadMicroApp(
        {
          name: "teachPptPC",
          entry: entryUrl,
          container: "#ppt-container",
          props: {
            // preload: true ppt预加载的时候要传过去的参数
            args: {
              token: store.state.token,
              appName: "teachPptPC",
              runtime: {
                playPptRule: {
                  play: "demonstrate",
                  stop: "preview",
                },
              },
              os: store.state.hardwareModel,
              // preload: true
            },
            micrAppUrl: entryUrl,
            screening: true,
            screenType: "preview",
          },
          configuration: { $$cacheLifecycleByAppName: true }, // TODO: $$cacheLifecycleByAppName是qiankun的一个内部实验性开关
        },
        {
          sandbox: { experimentalStyleIsolation: true },
        }
      );
      await pptFun.mountPromise;
      setMicroAppMap("teachPptPC", pptFun);
      logger.info("ppt加载完成");
    };

    const goBack = async () => {
      // 用于安卓端，返回时卸载ppt
      logger.info("pptFun", pptFun);
      if (pptFun) {
        if (pptFun.preUnmount) {
          logger.info("我要卸载了pre");
          await pptFun.preUnmount();
        }
        pptFun.unmount();
        logger.info("卸载成功");
      } else {
        logger.info("没有卸载直接返回");
      }
      // history.back();
      hidPPT();
    };

    onMounted(async () => {
      firstPptUrl.url = props.pptUrl;
      logger.info("ppturl", firstPptUrl.url);
      pptLoading.value = true;
      loadPPT();
      handleFirstLoadingPPT();

      // 接收子应用message
      eventCenter.sub(event.COMMON_NOTIFY_MESSAGE, (res) => {
        const { type, element } = res;
        antdMessage.config({
          top: `100px`,
          maxCount: 3,
          getContainer: () => element || document.body,
        });
        const msg = (
          <span style="margin-left:10px; font-size:32px">{res.message}</span>
        );
        let msgFunc = () => {
          logger.info("msgFunc");
        };
        switch (type) {
          case "info":
            msgFunc = antdMessage.info;
            break;
          case "error":
            msgFunc = antdMessage.error;
            break;
          case "success":
            msgFunc = antdMessage.success;
            break;
          case "warning":
            msgFunc = antdMessage.warning;
            break;
          default:
            msgFunc = antdMessage.info;
        }
        msgFunc(msg, 3, () => {
          antdMessage.destroy();
        });
      });
    });
    onUnmounted(() => {
      logger.info("unmounted");
      // 用于pc端，goback返回时卸载ppt
      if (isAndroid.value) {
        return;
      }
      if (pptFun) {
        pptFun.unmount();
        logger.info("卸载成功");
      } else {
        logger.info("没有卸载直接返回");
      }
    });
    return {
      pptLoadTxt,
      pptLoading,
      isAndroid,
      goBack,
    };
  },
});
