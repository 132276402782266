
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  onActivated,
  toRefs,
  watch,
  toRaw,
} from "vue";
import store, { MutationNames } from "@/store/index";
import { ElMessage } from "element-plus";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import {
  getTeacherInfo,
  getCoursewares,
  getBooks,
  getSections,
  addViewLog,
  addDownloadLog,
  addInsViewLog,
  copyOfficial,
  getInstitutionCoursewares,
  getSchoolCoursewares,
  getSchoolInstitutionInfo,
  dataEmbeddingPoint,
} from "@/services";
import {
  eventCenter,
  event,
  usePageTableContext,
} from "@evideo/frontend-utils";
import servers from "@/config/servers";
import config from "@/config/app.config";
import CustomAudio from "@/components/CustomAudio.vue";
import CustomPpt from "@/components/CustomPPT.vue";
import remindEmptyResource from "@/components/remindEmptyResource.vue";
import ShowResource from "@/components/showResource";
import { startFlashPlayer } from "@/electronService";
import pageTableContextMap from "@/utils/page-table-context-map";
import _ from "lodash";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";

export default defineComponent({
  components: {
    CustomAudio,
    CustomPpt,
    ShowResource,
    remindEmptyResource,
    Swiper,
    SwiperSlide,
  },
  setup() {
    let tabsPlatform = ref(true);
    let pptUrl = ref("");
    const CascaderRef = ref();
    let musicUrl = ref("");
    let CoursewaresId = ref("");
    let loading = ref(false);
    let isAndroid = ref(true);
    let firstLoading = ref(true);
    let mySwiper = ref(null);
    const router = useRouter();
    const teacherName = ref(store.state.nickName);
    const schoolName = ref(store.state.schoolName);
    let currentPage = ref(1);
    let totalPage = ref(1);
    let message = ref("");
    let searchName = ref("");
    let imgVisible = ref(false);
    let audioVisible = ref(false);
    let videoVisible = ref(false);
    let middleTeachResourceEight = reactive([]);
    let lastTeachResourceEight = reactive([]);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    const showResourceRef = ref(null);
    let audioSrc = ref("");
    let cascaderList = ref([]);
    let teachResourceEight = reactive([]);
    let teachResourceList = reactive([]);
    let firstLoad = ref(false);
    let noData = ref(false);
    let noDataMsg = ref(false);
    const typeOptions = [
      {
        value: "ppt",
        label: "ppt",
      },
      {
        value: "image",
        label: "图片",
      },
      {
        value: "video",
        label: "视频",
      },
      {
        value: "audio",
        label: "音频",
      },
      {
        value: "swf",
        label: "flash",
      },
      // {
      //   value: "pdf",
      //   label: "pdf",
      // },
    ];
    let typeValue = ref("");
    let refreshItem = ref(0);
    let videoSrc = ref("");
    let imgSrc = ref("");
    const device = navigator.userAgent;
    const android =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (android) {
      isAndroid.value = false;
    }

    const cascaderState = reactive({
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let dataInfo = [];
          let nodes = "";
          if (level === 0) {
            getBooks({ per_page: 1000, show_modules: 1 }).then((data) => {
              dataInfo.push(...data.data.items);
              level + 1;
              nodes = dataInfo.map((item) => ({
                value: item.id,
                label: item.name,
                leaf: level >= 1,
              }));
              resolve(nodes);
            });
          } else {
            getSections(node.value).then((data) => {
              dataInfo.push(...data.data);
              level + 1;
              nodes = dataInfo.map((item) => ({
                value: item.id,
                label: item.name,
                leaf: level >= 1,
              }));
              resolve(nodes);
            });
          }
        },
      },
    });

    // tab栏的值
    const TabTypeMap = {
      School: "school",
      Institution: "institution",
      Platform: "platform",
    };
    // 当前tab栏
    const currentTab = ref(TabTypeMap.Platform);

    // 是否展示区域课件
    const showIns = ref(false);

    // 学校id
    const schoolId = ref();

    // 级联选择书本的完整路径
    const bookSelectFullValue = ref();

    // 获取检索表单的参数上下文工具
    const { getContextInfo, saveContextInfo, deleteContextInfo } =
      usePageTableContext();
    const handleGetPageContext = () => {
      const context = getContextInfo(pageTableContextMap.COURSEWARE_CENTER);
      logger.info(context);
      const {
        currentTab: cTab,
        CoursewaresId: cId,
        typeValue: tValue,
        currentPage: cPage,
        searchName: sname,
        bookSelectFullValue: bsfValue,
      } = context;
      if (cTab) {
        currentTab.value = cTab;
      }
      if (cId) {
        CoursewaresId.value = cId;
      }
      if (tValue) {
        typeValue.value = tValue;
      }
      if (sname) {
        searchName.value = sname;
      }
      if (bsfValue) {
        bookSelectFullValue.value = bsfValue;
      }
      if (cPage && cPage > 1) {
        currentPage.value = cPage;
      }

      deleteContextInfo(pageTableContextMap.COURSEWARE_CENTER);
    };

    const handleSavePageContext = () => {
      const pageContext = {
        // 当前课件是平台/区域/还是学校
        currentTab: currentTab.value,
        // 当前课件所属教材筛选项
        CoursewaresId: CoursewaresId.value,
        // 当前课件类型筛选
        typeValue: typeValue.value,
        currentPage: currentPage.value,
        searchName: searchName.value,
        bookSelectFullValue: toRaw(bookSelectFullValue.value),
      };
      saveContextInfo(pageTableContextMap.COURSEWARE_CENTER, pageContext);
    };

    const handleGetInsInfoBySchoolId = async () => {
      getSchoolInstitutionInfo(schoolId.value).then(({ data }) => {
        logger.info("data", data);
        if (data && data.uplevel_ins_data && data.uplevel_ins_data.length > 0) {
          showIns.value = true;
        }
      });
    };

    onBeforeMount(() => {
      const device = navigator.userAgent;
      const android =
        device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      if (android) {
        isAndroid.value = false;
      }
      handleGetPageContext();
    });
    onMounted(() => {
      init();
      const param = {
        book_section_id: CoursewaresId.value || undefined,
        name: searchName.value || undefined,
        type: typeValue.value || undefined,
      };
      initGetResourceInfo(param);
      firstLoading.value = false;
      // getResourceInfo(param);
      getTeacherData();
      handleGetInsInfoBySchoolId();
      logger.info("isAndroid", isAndroid.value);

      // 预加载ppt
      // if (store.state.firstLoadPPT) {
      //   // console.time("开始");
      //   loading.value = true;
      //   loadPPT();
      //   store.commit(MutationNames.CHANGE_FIRSTLOADPPT, false);
      // }
      // 接收上级应用 预加载完成，可以卸载应用了
      // eventCenter.sub("teachPptPC-preloadOk", () => {
      //   logger.info("收到");
      //   // console.timeEnd("开始");
      //   eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, { app: "teachPptPC" });
      //   loading.value = false;
      // });
    });
    onActivated(() => {
      logger.info("firstLoadPPT", store.state.firstLoadPPT);
      if (android) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
      if (firstLoad.value) {
        refreshItem.value++;
        searchName.value = "";
        CoursewaresId.value = "";
        currentPage.value = 1;
        showResourceRef.value.reload();
        getResourceInfo();
        mySwiper.value.activeIndex = 1;
      }
    });
    // tab栏修改
    const handleTabClick = (type) => {
      if (currentTab.value !== type) {
        currentTab.value = type;
        const params = {
          book_section_id: CoursewaresId.value || undefined,
          name: searchName.value || undefined,
          type: typeValue.value || undefined,
        };
        currentPage.value = 1;
        getResourceInfo(params);
        mySwiper.value.activeIndex = 1;
      }
    };
    const getResourceFuncByTabType = () => {
      let func = undefined;
      switch (currentTab.value) {
        case TabTypeMap.Platform:
          func = getCoursewares;
          break;
        case TabTypeMap.Institution:
          func = getInstitutionCoursewares;
          break;
        case TabTypeMap.School:
          func = getSchoolCoursewares;
          break;
        default:
          func = getCoursewares;
          break;
      }
      return func;
    };

    // 资源类型
    const bindIcon = (icon) => {
      return require("@/assets/img/myCourseWare/courseWare/" + icon + ".png");
    };
    const loadPPT = async () => {
      logger.info("预加载");
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      // let entryUrl = "http://192.168.74.175:8090/";
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      logger.info("entryUrl", entryUrl);
      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: "teachPptPC",
        // el: "#ppt-preloadtest",
        el: "#ppt-preload",
        entry: entryUrl,
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          preload: true,
        },
        configuration: { $$cacheLifecycleByAppName: true },
      });
    };

    const getTeacherData = () => {
      getTeacherInfo({}).then((data) => {
        logger.info("teacherName", data);
        teacherName.value = data.nick_name;
        schoolId.value = data.school_id;
        store.commit(MutationNames.CHANGE_NICKNAME, data.nick_name);
      });
    };
    // 切换课件类型时重新请求课件列表
    const getResourceInfo = (params) => {
      mySwiper.value.touches.diff = 0;
      teachResourceEight.length = 0;
      middleTeachResourceEight.length = 0;
      lastTeachResourceEight.length = 0;
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: 1,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        if (data.data.items.length === 0) {
          noData.value = false;
          noDataMsg.value = true;
        } else {
          noData.value = true;
        }
        teachResourceEight.length = 0;
        teachResourceEight.push(...data.data.items);
        logger.info("初始化拿数据data===>", teachResourceEight);
        // 选择类型或搜索之后判断边界值
        if (currentPage.value == 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if ((currentPage.value == totalPage.value) == 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        logger.info(
          "我在函数里判断完了",
          currentPage.value,
          totalPage.value,
          leftBorderFlag.value,
          rightBorderFlag.value
        );
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: 2,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        middleTeachResourceEight.length = 0;
        middleTeachResourceEight.push(...data.data.items);
        logger.info("初始化拿数据data===>", middleTeachResourceEight);
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: 3,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        lastTeachResourceEight.length = 0;
        lastTeachResourceEight.push(...data.data.items);
        logger.info("初始化拿数据data===>", lastTeachResourceEight);
      });
      // searchFunc({
      //   is_primary: 0,
      //   per_page: 8,
      //   page: currentPage.value,
      //   book_show_modules: 1,
      //   ...params,
      // }).then((data) => {
      //   teachResourceEight.length = 0;
      //   teachResourceList.length = 0;
      //   totalPage.value = data.data.meta.last_page;
      //   if (data.data.items.length === 0) {
      //     noData.value = false;
      //   } else {
      //     noData.value = true;
      //   }
      //   teachResourceEight.push(...data.data.items);
      // });
    };
    const initGetResourceInfo = (params) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: currentPage.value,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if (currentPage.value % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...data.data.items);
          mySwiper.value.activeIndex = 1;
        }
        if (currentPage.value % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...data.data.items);
          mySwiper.value.activeIndex = 2;
        }
        if (currentPage.value % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...data.data.items);
          mySwiper.value.activeIndex = 3;
        }
        // 判断结束
        logger.info(
          "这边初始化数据结束，开始判断边界值===>",
          currentPage.value,
          totalPage.value
        );
        if (currentPage.value === 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if (
          currentPage.value === totalPage.value &&
          totalPage.value === 1
        ) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else if (
          currentPage.value === totalPage.value &&
          totalPage.value !== 1
        ) {
          leftBorderFlag.value = true;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        if (data.data.items.length === 0) {
          noData.value = false;
          noDataMsg.value = true;
        } else {
          noData.value = true;
        }
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: currentPage.value + 1,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if ((currentPage.value + 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value + 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value + 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...data.data.items);
        }
        // 判断结束
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        // middleTeachResourceEight.push(...data.data.items);
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: currentPage.value - 1,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if ((currentPage.value - 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value - 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value - 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...data.data.items);
        }
        // 判断结束
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        // lastTeachResourceEight.push(...data.data.items);
      });
    };
    // // 上一页
    // const handlePrevious = () => {
    //   if (currentPage.value === 1) {
    //     message.value = "已经是第一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     currentPage.value--;
    //     teachResourceEight.length = 0;
    //     const params = {
    //       book_section_id: CoursewaresId.value,
    //       name: searchName.value,
    //       type: typeValue.value,
    //     };
    //     getResourceInfo(params);
    //   }
    // };
    // // 下一页
    // const handleNext = () => {
    //   if (currentPage.value === totalPage.value) {
    //     message.value = "已经是最后一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     currentPage.value++;
    //     teachResourceEight.length = 0;
    //     const params = {
    //       book_section_id: CoursewaresId.value,
    //       name: searchName.value,
    //       type: typeValue.value,
    //     };
    //     getResourceInfo(params);
    //   }
    // };
    // 获取
    const handleCopyOfficial = (e) => {
      const params = { official_courseware_id: e.id };
      copyOfficial(params).then((data) => {
        logger.info("copyOfficial", data);
        if (data.message === "success") {
          addDownloadLog(e.id);
          ElMessage.success({
            offset: 200,
            message: "获取成功，已添加至“我的课件”",
            type: "success",
          });
        }
      });
    };
    // 数据埋点
    const handleDataReport = async (item) => {
      logger.info("item.id", item.id);
      let ownerType = "";
      if (currentTab.value === "platform") {
        ownerType = "official";
      } else if (currentTab.value === "institution") {
        ownerType = "institution";
      } else {
        ownerType = "school";
      }
      const eventattr = { id: item.id, owner_type: ownerType };
      logger.info("eventattr", eventattr);
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_coursewarecenter_open",
          pageid: "coursewarecenter",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        logger.info("data", data);
        const body = encodeURIComponent(JSON.stringify(data));
        logger.info("body", body);
        await dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          async function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            logger.info("安卓调用完成", responseData);
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_coursewarecenter_open";
            data.pageid = "coursewarecenter";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            logger.info("body", body);
            await dataEmbeddingPoint(body);
          }
        );
      }
    };
    const handleCardClick = async (e) => {
      await handleDataReport(e);
      let addViewFunc = undefined;
      if (currentTab.value === TabTypeMap.Platform) {
        addViewFunc = addViewLog;
      } else {
        addViewFunc = addInsViewLog;
      }
      addViewFunc(e.id).then(() => {
        const params = {
          book_section_id: CoursewaresId.value,
          name: searchName.value,
          type: typeValue.value,
        };
        // getResourceInfo(params);
        initGetResourceInfo(params);
      });
      logger.info("audio:", e.url);
      if (e.type === "ppt") {
        handleSavePageContext();
        goToMaterialPpt(e.url);
      } else if (e.type === "swf") {
        handleStartFlashPlayer(e);
      } else {
        showResourceRef.value.handleShowResource(e.type, true, e.url);
      }
    };

    const checkHasFlashPlayer = () => {
      const list = store.state.registedEvents || [];
      try {
        // windows
        if (
          isAndroid.value === true &&
          list.indexOf("start-flash-player") > -1
        ) {
          return true;
        } else if (
          isAndroid.value == false &&
          list.indexOf("openCourseWare") > -1
        ) {
          // 安卓
          return true;
        }
      } catch (error) {
        return false;
      }

      return false;
    };

    // 调用载体端启动flash播放器
    const handleStartFlashPlayer = async (courseWare) => {
      if (checkHasFlashPlayer() == false) {
        ElMessage.error({
          offset: 200,
          message: "该系统版本暂不支持预览本类型，请升级系统",
          type: "error",
        });
        return;
      }

      // windows
      if (isAndroid.value === true) {
        try {
          startFlashPlayer(toRaw(courseWare));
        } catch (err) {
          console.error(err);
          ElMessage.error({
            offset: 200,
            message: "打开课件异常，请重试",
            type: "error",
          });
        }
      } else {
        // android
        if (!window.WebViewJavascriptBridge) {
          console.error("WebViewJavascriptBridge未注册");
          ElMessage.error({
            offset: 200,
            message: "系统异常，请重试",
            type: "error",
          });
          return;
        }
        try {
          // const { eventCode, success } =
          window.WebViewJavascriptBridge.callHandler(
            "openCourseWare",
            JSON.stringify(courseWare)
          );
          // if (eventCode !== 0 || success == false) {
          //   throw new Error("调用失败");
          // }
        } catch (err) {
          console.error(err);
          ElMessage.error({
            offset: 200,
            message: "打开课件异常，请重试",
            type: "error",
          });
        }
      }
    };

    const handleSearch = _.debounce(() => {
      logger.info("search", searchName.value);
      currentPage.value = 1;
      const params = {
        name: searchName.value,
        book_section_id: CoursewaresId.value,
        type: typeValue.value,
      };
      getResourceInfo(params);
      mySwiper.value.activeIndex = 1;
    }, 500);
    const handleChangeType = () => {
      logger.info("typeValue", typeValue);
      currentPage.value = 1;
      const params = {
        name: searchName.value,
        book_section_id: CoursewaresId.value,
        type: typeValue.value,
      };
      getResourceInfo(params);
      mySwiper.value.activeIndex = 1;
      logger.info("params", params);
      // 返回第一页
      // currentPage.value = 1;
    };
    const goToMaterialPpt = (pptUrl) => {
      firstLoad.value = false;
      router.push({
        path: "/courseWarePpt",
        query: {
          pptUrl,
          goBackUrl: "coursewareCenterCartoon",
        },
      });
    };
    const handleGoToLogin = () => {
      firstLoad.value = true;
      refreshItem.value = 0;
      CoursewaresId.value = "";
      if (android) {
        // window.WebViewJavascriptBridge.callHandler(
        //   "closeTeachMaterial", // native中注册时的key值
        //   null // native中注册方法中的传入的数据
        // );
        // 现在不在该页面向Android去发送隐藏的方法，改为到blank页面去发送
        router.replace({
          path: "/blank",
          query: {
            skinType: 0,
          },
        });
      } else {
        router.push({
          // path: "/home",
          path: "/login",
        });
      }
    };
    // 关闭资源预览
    const handleCloseShow = (type) => {
      if (type === "audio") {
        audioVisible.value = false;
        audioSrc.value = "";
      } else if (type === "video") {
        videoVisible.value = false;
        videoSrc.value = "";
      } else if (type === "img") {
        imgVisible.value = false;
        imgSrc.value = "";
      }
    };
    const handleChangeCoursewares = (value) => {
      currentPage.value = 1;
      let params = {
        book_section_id: "",
        name: "",
        type: typeValue.value,
      };
      if (searchName.value) {
        params.name = searchName.value;
      }
      if (value) {
        const num = value.length - 1;
        params.book_section_id = value[num];
        CoursewaresId.value = value[num];
      } else {
        CoursewaresId.value = "";
      }
      getResourceInfo(params);
      mySwiper.value.activeIndex = 1;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (to.name === "Home") {
        firstLoad.value = true;
      }
      next();
    });
    //  初始化滑屏
    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };
    const onSwiper = (swiper) => {
      logger.info("onSwiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", currentPage.value);
    };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      logger.info(
        "点击按钮切换到下一页函数里拿到的当前页数是===>",
        currentPage.value + 1
      );
      if (currentPage.value === totalPage.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
        // currentPage.value++;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      logger.info(
        "点击按钮切换到上一页函数里拿到的当前页数是===>",
        currentPage.value - 1
      );
      if (currentPage.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info(
          "activeIndex页面切换到上一页函数里拿到的当前页数是===>",
          currentPage.value - 1
        );
        logger.info("activeIndex===>", mySwiper.value.activeIndex);
        currentPage.value--;
        const params = {
          name: searchName.value,
          book_section_id: CoursewaresId.value,
          type: typeValue.value,
        };
        if (currentPage.value !== 1) {
          getPrevData(currentPage.value - 1, params);
        }
      }
      // getPrevData(currentPage.value - 1, params);
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info(
            "activeIndex1页面切换到下一页函数里拿到的当前页数是===>",
            currentPage.value + 1
          );
          logger.info("activeIndex===>", mySwiper.value.activeIndex);
          currentPage.value++;
          const params = {
            name: searchName.value,
            book_section_id: CoursewaresId.value,
            type: typeValue.value,
          };
          if (currentPage.value !== totalPage.value) {
            getNextData(currentPage.value + 1, params);
          }
        } else {
          logger.info("翻页失败");
        }
        // getNextData(currentPage.value + 1, params);
      }
    };
    //  切换时获取下一页数据的函数
    const getNextData = (page, params) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: page,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        const switchTeachMaterialList = data.data.items;
        logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
        logger.info("拿数据时的页面是===>", currentPage.value);
        if ((currentPage.value + 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...switchTeachMaterialList);
        }
        if ((currentPage.value + 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...switchTeachMaterialList);
        }
        if ((currentPage.value + 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...switchTeachMaterialList);
        }
      });
    };
    //  切换时获取上一页数据的函数
    const getPrevData = (page, params) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: page,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        const switchTeachMaterialList = data.data.items;
        logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
        logger.info("拿数据时的页面是===>", currentPage.value);
        if ((currentPage.value - 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...switchTeachMaterialList);
        }
        if ((currentPage.value - 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...switchTeachMaterialList);
        }
        if ((currentPage.value - 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...switchTeachMaterialList);
        }
      });
    };
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          leftBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        logger.info("总页数", totalPage.value);
        if (currentPage.value === totalPage.value) {
          rightBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          rightBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        logger.info("在这看触摸距离startX===>", mySwiper.value.touches.startX);
        logger.info(
          "在这看触摸距离currentX===>",
          mySwiper.value.touches.currentX
        );
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === totalPage.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );
    return {
      onSwiper,
      next,
      previous,
      firstLoading,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      getPrevData,
      getNextData,
      onSlideChange,
      mySwiper,
      leftBorderFlag,
      rightBorderFlag,
      middleTeachResourceEight,
      lastTeachResourceEight,
      teacherName,
      teachResourceEight,
      cascaderList,
      currentPage,
      totalPage,
      searchName,
      imgVisible,
      audioVisible,
      videoVisible,
      audioSrc,
      videoSrc,
      imgSrc,
      noDataMsg,
      isAndroid,
      loading,
      musicUrl,
      bindIcon,
      // handlePrevious,
      // handleNext,
      handleSearch,
      handleGoToLogin,
      handleCloseShow,
      handleCardClick,
      handleChangeCoursewares,
      ...toRefs(cascaderState),
      CascaderRef,
      refreshItem,
      handleCopyOfficial,
      pptUrl,
      showResourceRef,
      schoolName,
      noData,
      typeOptions,
      typeValue,
      handleChangeType,
      tabsPlatform,
      handleTabClick,
      currentTab,
      showIns,
      TabTypeMap,
      bookSelectFullValue,
      init,
      initGetResourceInfo,
    };
  },
});
