
import { defineComponent, ref, computed } from "vue";
import logger from "@evideo/logger";
export default defineComponent({
  name: "CustomAudio",
  props: {
    musicUrlF: String,
    meta: Object,
  },
  setup(props) {
    const audioPlayer = ref<HTMLVideoElement>();
    let audioStatus = ref(true);
    let audioTxt = ref("播放");
    let musicUrl = ref(props.musicUrlF);
    logger.info("player", audioPlayer);
    logger.info("musicUrlF", props.musicUrlF);
    const overAudio = () => {
      audioStatus.value = true;
      audioTxt.value = "播放";
    };
    const handlePause = () => {
      const player = audioPlayer.value;
      if (player) {
        logger.info("当前时间:", player.currentTime);
        logger.info("总时间:", player.duration);
        if (player.paused) {
          player.play();
          audioStatus.value = false;
          audioTxt.value = "暂停";
        } else {
          player.pause();
          audioStatus.value = true;
          audioTxt.value = "播放";
        }
      }
      // if (this.player.paused) {
      //   this.player.play();
      //   audioStatus.value = false;
      //   audioTxt.value = "暂停";
      // } else {
      //   this.player.pause();
      //   audioStatus.value = true;
      //   audioTxt.value = "播放";
      // }
    };
    return {
      audioPlayer,
      audioStatus,
      audioTxt,
      musicUrl,
      overAudio,
      handlePause,
    };
  },
});
