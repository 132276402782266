import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9ceb94ec")
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "dvBtn" }
const _hoisted_3 = {
  key: 0,
  class: "triangle-right"
}
const _hoisted_4 = {
  key: 1,
  class: "square"
}
const _hoisted_5 = { class: "dvTxt" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("audio", {
      id: "audio",
      ref: "audioPlayer",
      src: _ctx.musicUrl,
      onEnded: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.overAudio && _ctx.overAudio(...args))),
      hidden: "true",
      controls: ""
    }, null, 40, _hoisted_1),
    _createElementVNode("div", {
      class: "dvAudio",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePause && _ctx.handlePause(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.audioStatus === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.audioStatus === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, "点击" + _toDisplayString(_ctx.audioTxt), 1)
    ])
  ]))
}